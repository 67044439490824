import demo from "../../assets/imgs/demo.png";
import Lotus_logo from "../../assets/imgs/Lotus_logo.png";
import { Outlet, Link, useLocation, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateLanguage } from "../../store/slices/langSlice";
import { useRef } from "react";
import languagejson from "../../language.json";
import lotuslogo from "../../assets/imgs/lotuslogopublicepage.png";
import leftfooterlogo from "../../assets/imgs/leftfooterlogopublicpages.png"
import rightfooterlogo from "../../assets/imgs/rightfooterlogopublicepages.png"
import { FaFacebookF, FaTwitter, } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa6";

const Layout = () => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.language.value);
  const languages = languagejson[lang];

  const closeModal = useRef(null);
  const closeModal2 = useRef(null);
  const location = useLocation();
  const publicPages = () =>
    location.pathname == "/" ||
    location.pathname == "/home" ||
    location.pathname == "/about" ||
    location.pathname == "/privacy" ||
    location.pathname == "/terms" ||
    location.pathname == "/termspolicy" ||
    location.pathname == "/support"
  return !publicPages() ? (
    <>
      <div className="login">
        <div className="login-wrapper">
          <div className="login-demo" title="View tutorial">
            <Link to="#">
              <img src={demo} alt="Demo" />
            </Link>
          </div>
          <div
            className="dropdown dropdown-language"
            id="lang-change"
            data-toggle="tooltip"
            data-placement="top"
          >
            <span></span>
            <a
              href="#"
              className="question-options language"
              id="dropdownLang"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              <span className="language" id="lang-name">
                ({lang})
              </span>
            </a>
            <ul
              className="dropdown-menu topMenu"
              aria-labelledby="dropdownLang"
            >
              <li
                to="#"
                id="englishLang"
                onClick={() => dispatch(updateLanguage("EN"))}
              >
                (EN) English
              </li>
              <li
                href="#"
                id="spanishLang"
                onClick={() => dispatch(updateLanguage("ES"))}
              >
                (ES) Español
              </li>
            </ul>
          </div>
          <div className="logo text-center">
            <img src={Lotus_logo} alt="Lotus Logo" />
          </div>
          <section className="login-bg-container">
            <Outlet />
          </section>
        </div>
      </div>
      <div
        className="modal fade"
        id="CounselorModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
      >
        <div
          className="modal-dialog assign-strands modal-block"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header counsellerModal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                ref={closeModal}
              >
                <span aria-hidden="true" className="text-light">
                  ×
                </span>
              </button>
              <h4 className="modal-title" id="myModalLabel">
                {languages.help}
              </h4>
            </div>
            <div className="modal-body">
              <div className="boot-row">
                <div className="col-md-12">
                  <div className="block-text">
                    <div className="inner-block counsellerInner">
                      <p>
                        <strong>{languages.whoCounselor}</strong>
                      </p>
                      <p>{languages.counselorData}</p>
                    </div>
                    <div className="inner-block counsellerInner">
                      <p>
                        <strong>{languages.whoCounselee}</strong>
                      </p>
                      <p>{languages.counseleeData}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <Link
                className="btn btn-default registerHelpOk"
                to={"/register?role=counselor"}
                onClick={() => closeModal.current.click()}

              // data-dismiss="modal"
              >
                {languages.gotIt}
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="CounselingModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
      >
        <div
          className="modal-dialog assign-strands modal-block"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header counsellerModal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                ref={closeModal2}
              >
                <span aria-hidden="true" className="text-light">
                  ×
                </span>
              </button>
              <h4 className="modal-title" id="myModalLabel">
                {languages.help}
              </h4>
            </div>
            <div className="modal-body">
              <div className="boot-row">
                <div className="col-md-12">
                  <div className="block-text">
                    <div className="inner-block counsellerInner">
                      <p>
                        <strong>{languages.whoCounselor}</strong>
                      </p>
                      <p>{languages.counselorData}</p>
                    </div>
                    <div className="inner-block counsellerInner">
                      <p>
                        <strong>{languages.whoCounselee}</strong>
                      </p>
                      <p>{languages.counseleeData}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <Link
                className="btn btn-default registerHelpOk"
                to={"/register?role=counselee"}
                onClick={() => closeModal2.current.click()}
              // data-dismiss="modal"
              >
                {languages.gotIt}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : location.pathname == "/termspolicy" ? <Outlet /> : (
    <>
      <nav className="navbar-default homeNav">
        <div className="navWrap">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
              aria-expanded="false"
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
            <Link to="/">
              <img src={lotuslogo} alt="Logo" />
            </Link>
          </div>

          <div
            className="collapse navbar-collapse"
            id="bs-example-navbar-collapse-1"
          >
            <ul className="nav navbar-nav navbar-right">
              <li>
                <NavLink to={"/about"}>ABOUT US</NavLink>
              </li>
              <li>
                <a href="#features" data-slide-to="3">
                  FEATURES
                </a>
              </li>
              <li>
                <a href="#testimonials">TESTIMONIALS</a>
              </li>
              <li>
                <NavLink to={"/support"}>SUPPORT</NavLink>
              </li>
              <li>
                <Link to={"/login"}>LOGIN</Link>
              </li>
              {/* <li>
                <Link to="/login" className="primaryBtn enrollBtn">
                  ENROLL
                </Link>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>

      <Outlet />

      <footer className="firstfooter" style={{ position: "relative" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <img src={leftfooterlogo} alt="Left Logo" />
            </div>
            <div className="col-md-6 col-sm-12">
              <img src={rightfooterlogo} alt="Supported By" />
            </div>
          </div>
        </div>
      </footer>
      <footer
        className="lastfooter"
        style={{
          paddingTop: "50px",
          paddingBottom: "50px",
          position: "relative",
        }}
      >
        <div className="container" style={{ marginLeft: 0 }}>
          <div className="row">
            <div className="col-md-6 col-sm-12" style={{ display: "flex" }}>
              <ul style={{ listStyle: "none", width: "50%" }}>
                <li>
                  <a href="#" style={{ fontWeight: "bold", color: "#fff" }}>
                    COMPANY
                  </a>
                </li>
                <li>
                  <a href="/about">ABOUT US</a>
                </li>
                <li>
                  <a href="/support">CONTACT</a>
                </li>
              </ul>
              <ul style={{ listStyle: "none" }}>
                <li>
                  <a href="#" style={{ fontWeight: "bold", color: "#fff" }}>
                    ABOUT APP
                  </a>
                </li>
                <li>
                  <a href="/#features">FEATURES</a>
                </li>
                <li>
                  <a href="/#testimonials">TESTIMONIALS</a>
                </li>
              </ul>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="socialLinksWrap">
                <span className="facebook"><FaFacebookF /></span>
                <span className="twitter"><FaTwitter /></span>
                <span className="linkedin"><FaLinkedinIn /></span>
              </div>
            </div>
          </div>
          <div className="row lastrow">
            <div className="col-md-10 col-sm-12">
              <span>Copyright © 2021. All Rights Reserved</span>
            </div>
            <div className="col-md-2 col-sm-12">
              <Link to="/termspolicy">
                <span>Terms & policy</span>
              </Link>
              {/* <Link to="/terms">
                <span>Terms</span>
              </Link>
              <span> | </span>
              <Link to="/privacy">
                <span>Policy</span>
              </Link> */}
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Layout;
