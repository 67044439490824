import $ from "jquery";

$(document).on("click", "#fliptoBack", function () {
  $(".login-card").addClass("flipped");
});
$(document).on("click", "#gotoNewData", function () {
  $("#form-login").hide();
  $("#loginForm").show();
});

$(document).on("click", function () {
  $("#fliptoregister").click(function () {
    $(".login-card").addClass("flipped");
  });

  $("#fliptoFront").click(function () {
    $(".login-card").removeClass("flipped");
  });

  $("#login-back").click(function () {
    $("#loginForm").hide();
    $("#form-login").show();
  });

  $("#academicsBtn").click(function () {
    $(".reg").css("display", "none");
    $(".counselling").hide();

    $(".back").css("background-color", "white");
    $(".counseller").show();
  });

  $("#counsellingBtn").click(function () {
    $(".reg").hide();
    $(".counseller").hide();
    $(".counselling").show();
    $(".back").css("background-color", "white");
  });

  $("#goToAcad").click(function () {
    $(".counselling").hide();
    $(".counseller").show();
  });
  $("#goToCouseling").click(function () {
    $(".counseller").hide();
    $(".counselling").show();
  });

  $("#regAsbacktoLogin").click(function () {
    $(".login-card").removeClass("flipped");
    $(".reg").show();
    $(".counseller").hide();
    $(".back").css("background-color", "rgb(142, 68, 173)");
  });

  $("#counsToLogin").click(function () {
    $(".login-card").removeClass("flipped");
    $(".reg").show();
    $(".back").css("background-color", "rgb(142, 68, 173)");
    $(".counselling").hide();
  });

  /*  current lp page start */

  $("#measurementdata").click(function () {
    $(".firsttabcontent").hide();
    $(".secondtabcontent").show();
  });
  $("#geometrydata").click(function () {
    $(".secondtabcontent").hide();
    $(".firsttabcontent").show();
  });

  /*  current lp page end */

  // Relationship User Status Modal

  $("#modalassociateBtn").click(function () {
    $(".modalBodyWrap").hide();
    $(".newDataAppear").show();
    $("#relationModalBackArrow").show();
    $(".relationModalTitleWrap").hide();
    $(".relationModalTitleWrapNewData").show();
    $(".relationBtnWrap").hide();
    $(".relationNewBtnWrap").show();
  });
  $(".relationModalBackArrow").click(function () {
    $(".relationModalTitleWrapNewData").hide();
    $(".newDataAppear").hide();
    $(".relationModalTitleWrap").show();
    $(".modalBodyWrap").show();
    $(".relationModalBackArrow").hide();
    $(".relationModalTitleWrapSecondNewData").hide();
    $(".secondNewDataAppear").hide();
    $(".relationBtnWrap").show();
    $(".relationNewBtnWrap").hide();
    $(".relationNewBtnWrapRejection").hide();
  });
  $(".modalRejectBtn").click(function () {
    $(".relationModalTitleWrap").hide();
    $(".modalBodyWrap").hide();
    $(".relationModalBackArrow").show();
    $(".relationModalTitleWrapSecondNewData").show();
    $(".secondNewDataAppear").show();
    $(".relationModalTitleWrapNewData").hide();
    $(".newDataAppear").hide();
    $(".relationBtnWrap").hide();
    $(".relationNewBtnWrapRejection").show();
  });
  $("#unAssignLpInput").on('input', function () {
    if ($(this).val().trim() === "") {
      $(".token-input-list").hide();
    } else {
      $(".token-input-list").show();
    }
  })
});
